import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import {
    Show,
    NumberField,
    TagField,
    TextField,
    MarkdownField,
    DateField,
} from "@refinedev/antd";
import { Typography } from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const { Title } = Typography;

export const BlogPostShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const  toolbarOptions  = {
        toolbar: [
            
     
        ],
    };

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Azonosító</Title>
            <NumberField value={record?.id ?? ""} />

            <Title level={5}>Cím</Title>
            <TextField value={record?.newsTitle} />

            <Title level={5}>Tartalom</Title>
            <ReactQuill style={{width: "30%"}} readOnly modules={toolbarOptions} value={record?.content} theme="snow"/>
            <br></br>
            <Title level={5}>Hír dátuma</Title>
            <DateField value={record?.date} />

            <Title level={5}>Hír létrehozásának dátuma</Title>
            <DateField value={record?.endDate} />

            <Title level={5}>Utolsó módosítás dátuma</Title>
            <DateField value={record?.lastModified} />
        </Show>
    );
};
