import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

export const BlogPostEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm();
    
    const  toolbarOptions  = {
        toolbar: [
            
            ["bold", "italic", "underline","strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                
                <Form.Item
                    label="Cím"
                    name={["newsTitle"]}
                    rules={[
                        {
                            required: true,
                            message:"A mező kitöltése kötelező!"
                        },
                    ]}
                >
                    <Input style={{width: "30%"}}/>
                </Form.Item>
                
                <Form.Item
                    label="Tartalom"
                    name="content"
                    rules={[
                        {
                            required: true,
                            message:"A mező kitöltése kötelező!"
                        },
                    ]}
                >
                <ReactQuill style={{width: "30%", height: 100}}  modules={toolbarOptions} theme="snow"/>
                </Form.Item>
                <br></br>
                <br></br>
                <br></br>
                
                <Form.Item
                    label="Hír dátuma"
                    name={["date"]}
                    rules={[
                        {
                            required: true,
                            message:"A dátum kitöltése kötelező!"
                        },
                    ]}
                    getValueProps={(value) => ({
                        value: value ? dayjs(value) : undefined,
                    })}
                >
                    <DatePicker style={{width: "30%"}} placeholder='Dátum kiválasztása'/>
                </Form.Item>

                <Form.Item
                    label="Hír létrehozásának dátuma"
                    name={["endDate"]}
                    rules={[
                        {
                            required: true,
                            message:"A dátum kitöltése kötelező!"
                        },
                    ]}
                    getValueProps={(value) => ({
                        value: value ? dayjs(value) : undefined,
                    })}
                >
                    <DatePicker style={{width: "30%"}} placeholder='Dátum kiválasztása'/>
                </Form.Item>

                <Form.Item
                    label="Utolsó módosítás dátuma"
                    name={["lastModified"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    getValueProps={(value) => ({
                        value: value ? dayjs(value) : undefined,
                    })}
                >
                    <DatePicker style={{width: "30%"}} placeholder='Dátum kiválasztása' />
                </Form.Item>
                
            </Form>
        </Edit>
    );
};
