import React from "react";
import routerProvider from "@refinedev/react-router-v6";
import { TitleProps} from "@refinedev/core";
import {
    Link
  } from "react-router-dom";

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <>

    <Link to="/">
      {collapsed ? (
        <img
          src={"sqs_logo-removebg-preview (1).png"}
          alt="SQS"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //          padding: "12px 24px",
          }}
        />
      ) : (<>
        <img
          src={"sqs_logo-removebg-preview (1).png"}
          alt="SQS"
          style={{
            width: "100%",
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      
      
        </>)}
    </Link>
        
  </>
);
