import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DeleteButton,
    MarkdownField,
    DateField,
    BooleanField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const NewsList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        resource:"news",
        syncWithLocation: true,
    });

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    /*const { data: categoryData, isLoading: categoryIsLoading } = useMany({
        resource: "news",
        ids: tableProps?.dataSource?.map((item) => item?.category?.id) ?? [],
        queryOptions: {
            enabled: !!tableProps?.dataSource,
        },
    });*/

    

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="Azonosító" />
                
                <Table.Column
                    dataIndex="date"
                    title="Hír dátuma"
                    render={(value) => (
                        <DateField value={value} />
                    )}
                    width="10%"
                />
                <Table.Column dataIndex="newsTitle" title="Cím" />
                
                <Table.Column
                    width={"20%"}
                    dataIndex="content"
                    title="Tartalom"
                    render={(value: any) => (
                        <MarkdownField value={value.slice(0, 80) + "..."} />
                    )}
                />


                <Table.Column
                    dataIndex="endDate"
                    title="Hír létrehozásának dátuma"
                    render={(value) => (
                        <DateField value={value} />
                    )}
                    width="20%"
                />
                <Table.Column
                    dataIndex="lastModified"
                    title="Utolsó módosítás dátuma"
                    render={(value) => (
                        <DateField value={value} />
                    )}
                    width="20%"
                />
                <Table.Column
                    title="Műveletek"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
