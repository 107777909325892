import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  ThemedLayoutV2,
  RefineThemes
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import { ConfigProvider } from "antd";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { NewsList } from "pages/blog-posts/list";
import {
  BlogPostCreate,
  BlogPostEdit,
  BlogPostShow,
} from "pages/blog-posts";
import { Login } from "pages/login";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Title } from "components/layout/title";
import simpleRestDataProvider from "./dataProvider";
import {AuthBindings } from "@refinedev/core"
import { AuthActionResponse, CheckResponse, OnErrorResponse } from "@refinedev/core/dist/interfaces";
//import { authProvider } from "authProvider";

function App() {
  //const API_URL = "http://127.0.0.1:8000/api";
  const API_URL = "/api";
  const dataProvider = simpleRestDataProvider(API_URL);
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  
  const mockUsers = [
    {
      username: "newsAdmin",
      password: "Pa$$word123!",
      roles: ["admin"],
    }
  ];

  const authProvider: AuthBindings = {
    login: async ({ username, password }) => {
      const user = mockUsers.find((item) => item.username === username && item.password === password);

      if (user) {
        localStorage.setItem("auth", JSON.stringify(user));
        return {
          success: true,
          redirectTo: "/",
        };
      }

      return {
        success: false,
        error: {
          message: "Login Error",
          name: "Invalid email or password",
        },
      };
    },
    getPermissions: () => {
      const user = localStorage.getItem("auth");

      if (user) {
          const { roles } = JSON.parse(user);

          return roles;
      }

      return null;
  },
    logout: async () => {
      localStorage.removeItem("auth");
      return {
        success: true,
        redirectTo: "/login",
      };
    },

    check: async () => {
      const user = localStorage.getItem("auth");

      if (user) {
          return {
              authenticated: true,
          };
      }

      return {
          authenticated: false,
          logout: true,
          redirectTo: "/login",
          error: {
              message: "Check failed",
              name: "Unauthorized",
          },
      };
  },

    onError: async (error) => {
      if (error.status === 401 || error.status === 403) {
          return {
              logout: true,
              redirectTo: "/login",
              error,
          };
      }

      return {};
  },
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <ConfigProvider theme={RefineThemes.Orange}>
            <Refine
              dataProvider={dataProvider}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              resources={[
                {
                  name: "news",
                  list: "/news",
                  create: "/news/create",
                  edit: "/news/edit/:id",
                  show: "/news/show/:id",
                  meta: {
                    canDelete: true,
                  },
                }
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                projectId: "0ISKRU-LFaxuy-LtJ19N",
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayoutV2 Header={Header} Title={Title}>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="news" />}
                  />
                  <Route path="news">
                      <Route index element={<  NewsList />} />
                      <Route path="create" element={<BlogPostCreate />} />
                      <Route path="edit/:id" element={<BlogPostEdit />} />
                      <Route path="show/:id" element={<BlogPostShow />} />
                  </Route>
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler />
            </Refine>
          </ConfigProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
