import React, { useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, TextField, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker, Button, Row, Col } from "antd";
import dayjs from "dayjs";
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

export const BlogPostCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm({
        resource:"news"
    });

    const  toolbarOptions  = {
        toolbar: [
            
            ["bold", "italic", "underline","strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    const Editor = () => {
        return  <ReactQuill  modules={toolbarOptions} theme="snow"/>;
    };

     
    return (
        <Create saveButtonProps={saveButtonProps}>
                <Form {...formProps} layout="vertical">
                
                    <Form.Item
                        label="Cím"
                        name={["newsTitle"]}
                        rules={[
                            {
                                required: true,
                                message:"A mező kitöltése kötelező!"
                            },
                        ]}
                    >
                        <Input style={{width: "30%"}}/>
                    </Form.Item>
                    
                    <Form.Item
                        label="Tartalom"
                        name="content"
                        rules={[
                            {
                                required: true,
                                message:"A mező kitöltése kötelező!"
                            },
                        ]}
                    >
                    <ReactQuill style={{width: "30%", height: 100}}  modules={toolbarOptions} theme="snow"/>
                    </Form.Item>
                    <br></br>
                    <br></br>
                    <br></br>
                    
                    <Form.Item
                        label="Hír dátuma"
                        name={["date"]}
                        rules={[
                            {
                                required: true,
                                message:"A dátum kitöltése kötelező!"
                            },
                        ]}
                        getValueProps={(value) => ({
                            value: value ? dayjs(value) : undefined,
                        })}
                    >
                        <DatePicker style={{width: "30%"}} placeholder='Dátum kiválasztása'/>
                    </Form.Item>

                    <Form.Item
                        label="Hír létrehozásának dátuma"
                        name={["endDate"]}
                        rules={[
                            {
                                required: true,
                                message:"A dátum kitöltése kötelező!"
                            },
                        ]}
                        getValueProps={(value) => ({
                            value: value ? dayjs(value) : undefined,
                        })}
                    >
                        <DatePicker style={{width: "30%"}} placeholder='Dátum kiválasztása'/>
                    </Form.Item>

                    <Form.Item
                        label="Utolsó módosítás dátuma"
                        name={["lastModified"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        getValueProps={(value) => ({
                            value: value ? dayjs(value) : undefined,
                        })}
                    >
                        <DatePicker style={{width: "30%"}} placeholder='Dátum kiválasztása' />
                    </Form.Item>
                    
                </Form>
        </Create>
    );
};
