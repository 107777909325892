import React from "react";
import { useLogin } from "@refinedev/core";
import {
    Row,
    Col,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "antd";
import "./styles.css";

const { Text, Title } = Typography;

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Login: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={2} className="title">
            Bejelentkezés
        </Title>
    );

    return (
      <AntdLayout className="layout">
      <Row
          justify="center"
          align="middle"
          style={{
              height: "100vh",
          }}
      >
          <Col xs={22}>
              <div className="container">
                  <div className="imageContainer">
                  <img src={"sqs_logo-removebg-preview (1).png"} alt="SQS Logo" width="70%" />
                  </div>
                  <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                      <Form<ILoginForm>
                          layout="vertical"
                          form={form}
                          onFinish={(values) => {
                              login(values);
                          }}
                          requiredMark={false}
                          initialValues={{
                              remember: false,
                          }}
                      >
                          <Form.Item
                              name="username"
                              label="Felhasználónév"
                              rules={[{ required: true , message:"A felhasználónév kitöltése kötelező!"}]}
                          >
                              <Input
                                  style={{  }}
                                  size="large"
                              />
                          </Form.Item>
                          <Form.Item
                              name="password"
                              label="Jelszó"
                              rules={[{ required: true, message:"A jelszó kitöltése kötelező!"}]}
                              style={{ marginBottom: "12px" }}
                          >
                              <Input
                                  style={{  }}
                                  type="password"
                                  placeholder="●●●●●●●●"
                                  size="large"
                              />
                          </Form.Item>
                          <br></br>
                          <Button
                              type="primary"
                              size="large"
                              htmlType="submit"
                              block
                          >
                              Bejelentkezés
                          </Button>
                      </Form>
                  </Card>
              </div>
          </Col>
      </Row>
  </AntdLayout>
    );
};